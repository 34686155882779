.contact-us-container {
    .contact-us-content {
        min-height: 80vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;
        padding-top: 112px;
        margin-bottom: 52px;

        @media (max-width: 820px) {
            padding-top: 30px;
        }

        @media (max-width: 520px) {
            flex-direction: column;
            align-items: center;
        }
    
        .contact-info {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .contact-us-header {
                margin-bottom: 20px;

                @media (max-width: 520px) {
                    margin-bottom: 0px;
                }
            }
            
            h2 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 10px;

                @media (max-width: 520px) {
                    display: none;
                }
            }
    
            p {
                margin-top: 6px;
                font-size: 16px;
                margin-bottom: 20px;
                line-height: 1.5;

                @media (max-width: 520px) {
                    display: none;
                }
            }
    
            .whatsapp-button-contact {
                margin-top: 20px;

                a {
                    text-decoration: none;
                }

                @media (max-width: 520px) {
                    display: none;
                }
    
                .whatsapp-button-contact-text {
                    background-color: transparent;
                    border: 2px solid #B38922;
                    padding: 10px 20px;
                    font-size: 16px;
                    cursor: pointer;
                    color: #B38922;
                    transition: background-color 0.3s, color 0.3s;
                    font-family: inherit;
                    align-items: center;
                    justify-content: center;
                    display: flex;

                    img {
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;
                    }
        
                    &:hover {
                        background-color: #B38922;
                        color: white;
                        cursor: pointer;
                    }
                }
            }
        }
    
        .contact-form {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media (max-width: 520px) {
                width: 80%;
            }
    
            h2 {
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 24px;

                @media (max-width: 520px) {
                    text-align: center;
                    margin-top: 0px;
                }
            }
    
            form {
                display: flex;
                flex-direction: column;
    
                input, textarea {
                    padding: 12px;
                    margin-bottom: 16px;
                    border: 1px solid #B38922;
                    font-size: 16px;
                    outline: none;
                    transition: border-color 0.3s;
                    margin-right: 0px;
                    font-family: inherit;
        
                    &:focus {
                        border-color: #34312F;
                    }
                }
    
                textarea {
                    height: 100px;
                    resize: vertical;
                    font-family: inherit;
                }
        
                .submit-button-contact {
                    padding: 12px 20px;
                    background-color: transparent;
                    border: 2px solid #B38922;
                    color: #B38922;
                    font-size: 18px;
                    font-weight: 700;
                    cursor: pointer;
                    transition: background-color 0.3s, color 0.3s;
                    font-family: inherit;
        
                    &:hover {
                        background-color: #B38922;
                        color: white;
                        cursor: pointer;
                    }
                }
            }    

            .social-media {
                display: flex;
                justify-content: center;
                gap: 40px;
                margin-top: 40px;
                align-items: center;
        
                .social-icon {
                    text-align: center;
            
                    img {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background-color: #F0F0F0;
                        padding: 10px;
                        cursor: pointer;
                    }
            
                    p {
                        font-size: 16px;
                        margin-top: 8px;
                        color: rgba(179,137,34,1);
                    }
                }
            }
        }
    }
}
  
@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;