.halal-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding-top: 96px;

    .halal-left-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 120px;
    }

    .halal-right-container {
        max-width: 40vw;
        .halal-title {
            font-size: 44px;
            color: @primary-color;
            margin-top: 0px;
        }

        .halal-text {
            font-size: 20px;
            color: black;
            text-align: justify;
        }
    }
}

@media (max-width: 820px) {
    .halal-container {
        flex-direction: column;

        .halal-left-container {
            margin-right: 0px;
            img {
                width: 30vw;
            }
        }

        .halal-right-container {
            max-width: 80vw;
            .halal-title {
                font-size: 36px;
                color: @primary-color;
                text-align: center;
            }
    
            .halal-text {
               text-align: justify;
            }
        }
    }
}

@media (max-width: 425px) {
    .halal-container {
        .halal-left-container {
            img {
                width: 60vw;
            }
        }
    }
}
@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;