.about-us-container {
    position: relative;
    overflow: hidden;
    
    .shop-leaf-left-about-us, .shop-leaf-right-about-us {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
        background-size: contain;
        background-repeat: no-repeat;
        will-change: transform;
    }
    
    .shop-leaf-left-about-us {
        top: 1020px;
        left: 0;
        width: 380px; /* Adjust the width */
        background-image: url('../../assets/images/shop-leaf-left-about-us.svg');

        @media (max-width: 520px) {
            width: 200px;
        }
    }
    
    .shop-leaf-right-about-us {
        top: 220px;
        right: 0;
        width: 380px; /* Adjust the width */
        background-image: url('../../assets/images/shop-leaf-right-about-us.svg');

        @media (max-width: 520px) {
            width: 200px;
        }
    }

    .shop-leaf-left {
        top: 1220px;
        left: 0;
        width: 420px; /* Adjust the width */
        background-image: url('../../assets/images/shop-leaf-left.svg');
        z-index: -1;
    }

    .about-us-background-rectangle {
        position: absolute;
        left: 0;
        width: 100vw;
        background-color: #FEDEA0;
        z-index: -2;
        height: 400px;
    }

    .about-us-header {
        padding-top: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        margin-bottom: 32px;
        z-index: 1;

        @media (max-width: 820px) {
            padding-top: 20px;
            flex-direction: column;
        }

        .about-us-header-logo-container {
            position: absolute;
            left: 60px;
            display: flex;
            align-items: center;

            @media (max-width: 520px) {
                position: relative;
                left: 0px;
            }

            .about-us-header-logo {
                width: 8vw;
                max-width: 80px;

                @media (max-width: 520px) {
                    width: 14vw;
                }
            }
        }

        .about-us-title-container {
            flex-grow: 1;
            display: flex;
            justify-content: center;

            .about-us-title {
                font-size: 72px;
                text-align: center;
                filter: drop-shadow(6px 6px #0000002f);

                @media (max-width: 520px) {
                    margin-bottom: 20px;
                }

                @media (max-width: 520px) {
                    font-size: 36px;
                }
            }
        }
    }

    .about-us-header-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        margin-bottom: 32px;
        z-index: 1;

        @media (max-width: 820px) {
            padding-top: 20px;
            flex-direction: column;
        }

        .about-us-header-logo-container {
            position: absolute;
            left: 60px;
            display: flex;
            align-items: center;

            @media (max-width: 520px) {
                position: relative;
                left: 0px;
            }

            .about-us-header-logo {
                width: 8vw;
                max-width: 80px;

                @media (max-width: 520px) {
                    width: 14vw;
                }
            }
        }

        .about-us-title-container {
            flex-grow: 1;
            display: flex;
            justify-content: center;

            .about-us-title {
                font-size: 72px;
                text-align: center;
                filter: drop-shadow(6px 6px #0000002f);

                @media (max-width: 520px) {
                    margin-bottom: 20px;
                }

                @media (max-width: 520px) {
                    font-size: 36px;
                }
            }
        }
    }

    .about-us-content-1 {
        display: flex;
        flex-direction: row;
        border-top: 1px solid black;

        @media (max-width: 820px) {
            flex-direction: column;
            align-items: center;
        }

        .about-us-content-1-img-container {
            padding: 52px;
            border-right: 1px solid black;

            @media (max-width: 820px) {
                border: none;
            }

            @media (max-width: 520px) {
                padding: 40px;
            }

            .about-us-content-1-img {
                width: 27vw;

                @media (max-width: 520px) {
                    width: 60vw;
                }
            }
        }

        .about-us-content-1-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center; 
            align-items: center; 
            text-align: justify;
            flex: 1; 

            .about-us-content-1-header {
                width: 50vw;
                font-size: 32px;

                @media (max-width: 820px) {
                    width: 80vw;
                }

                @media (max-width: 520px) {
                    font-size: 24px;
                }
            }

            .about-us-content-1-text {
                width: 50vw;
                font-size: 24px;

                @media (max-width: 820px) {
                    width: 80vw;
                }

                @media (max-width: 520px) {
                    font-size: 16px;
                }
            }
        }
    }

    .about-us-content-2 {
        display: flex;
        flex-direction: row-reverse;
        border-top: 1px solid black;
        border-bottom: 1px solid black;

        @media (max-width: 820px) {
            flex-direction: column;
            align-items: center;
        }

        .about-us-content-2-img-container {
            padding: 52px;
            border-left: 1px solid black;

            @media (max-width: 820px) {
                border: none;
            }

            @media (max-width: 520px) {
                padding: 40px;
            }

            .about-us-content-2-img {
                width: 27vw;

                @media (max-width: 520px) {
                    width: 60vw;
                }
            }
        }

        .about-us-content-2-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center; 
            align-items: center; 
            text-align: justify;
            flex: 1; 

            .about-us-content-2-text {
                width: 50vw;
                font-size: 24px;

                @media (max-width: 820px) {
                    width: 80vw;
                }

                @media (max-width: 520px) {
                    font-size: 16px;
                }
            }
        }
    }

    .about-us-vision {
        text-align: center;
        padding-bottom: 60px;

        .about-us-vision-title-container {
            .about-us-vision-title {
                margin-top: 40px;
                margin-bottom: 40px;
                font-size: 48px;
                filter: drop-shadow(6px 6px #0000002f);

                @media (max-width: 520px) {
                    font-size: 36px;
                }
            }
        }

        .about-us-vision-text-container {
            .about-us-vision-text {
                font-size: 24px;
                margin: 20px;

                @media (max-width: 520px) {
                    font-size: 16px;
                }
            }
        }
    }
}
@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;