.sub-hero-container {
    position: relative;
    width: 100%;
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .leaves-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;

        /* Leaf on the left */
        .leaf-left-container {
            position: absolute;
            left: 104px;
            transform: scaleX(-1) rotate(220deg) ; /* Rotate the left leaf */
        }

        .leaf-left {
            width: 25vw;
        }

        /* Logo in the middle */
        .logo-middle-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .logo-middle {
            width: 6vw; /* Logo size */
        }

        /* Leaf on the right */
        .leaf-right-container {
            position: absolute;
            right: 104px;
            transform: scaleX(-1) rotate(70deg); /* Rotate and flip the right leaf horizontally */
        }

        .leaf-right {
            width: 25vw;
        }
    }
}

@media (max-width: 820px) {
    .sub-hero-container {
        min-height: 40vh;
        // background-color: #34312F;
    
        .leaves-container {
            /* Leaf on the left */
            .leaf-left-container {
                left: 60px;
                transform: scaleX(-1) rotate(220deg) ; /* Rotate the left leaf */
            }
    
            .leaf-left {
                width: 25vw;
            }
    
            .logo-middle {
                width: 7vw; /* Logo size */
            }
    
            /* Leaf on the right */
            .leaf-right-container {
                position: absolute;
                right: 60px;
                transform: scaleX(-1) rotate(70deg); /* Rotate and flip the right leaf horizontally */
            }
    
            .leaf-right {
                width: 25vw;
            }
        }
    }
}

@media (max-width: 520px) {
    .sub-hero-container {
        min-height: 20vh;
    
        .leaves-container {
            /* Leaf on the left */
            .leaf-left-container {
                left: 20px;
                transform: scaleX(-1) rotate(220deg) ; /* Rotate the left leaf */
            }
    
            .leaf-left {
                width: 25vw;
            }
    
            .logo-middle {
                width: 12vw; /* Logo size */
            }
    
            /* Leaf on the right */
            .leaf-right-container {
                position: absolute;
                right: 30px;
                transform: scaleX(-1) rotate(70deg); /* Rotate and flip the right leaf horizontally */
            }
    
            .leaf-right {
                width: 25vw;
            }
        }
    }
}

@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;