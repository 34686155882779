.hero-container {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 80vh;
    width: 100vw; 
    padding-top: 96px;

    .hero-content {
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: row;
        max-width: 100vw; 
        transform: translateX(0);

        .hero-logo-container {
            padding-top: 32px;
            transform: translateX(0);

            img {
                width: 6vw;
                max-width: 100%; 
            }
        }

        .top-container-hero {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 52px;

            .top-left-container {
                transform: translateY(-20px);

                .birds {
                    position: absolute;
                    top: 8%;
                    left: 20%;
                    z-index: 200;
                }

                .bird1, .bird2, .bird3 {
                    position: absolute;
                    animation: fly 5s ease-in-out infinite;
                }

                .hero-text {
                    margin-top: 80px;
                    text-align: right; 

                    .hero-title {
                        font-size: 320px;
                        font-weight: 400;
                        margin: 0;
                        max-width: 100%;
                        background: -webkit-linear-gradient(0deg, rgba(179,137,34,1) 0%, rgba(254,222,160,1) 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        filter: drop-shadow(8px 8px #0000002f);
                        white-space: nowrap; 
                        
                        span {
                            font-family: Arial, Helvetica, sans-serif;
                            font-size: 120px;
                            color: black !important;
                        }
                    }

                    .hero-subtitle {
                        font-size: 34px;
                        color: rgba(179,137,34,1);
                        margin: 0;
                        font-weight: 800;
                        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);

                        @media (max-width: 1024px) {
                            font-size: 26px;
                        }
                        
                        &.hero-subtitle-zh {
                            font-size: 48px;
                            line-height: 1.5;
                            text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);

                            @media (max-width: 1024px) {
                                font-size: 36px;
                            }
                        }
                    }
                }
            }

            .top-right-container {
                .hero-product {
                    margin-top: 0px;

                    .product-image {
                        width: 45vw;
                        max-width: 100%; 
                        transform: translate(0, 40px); 
                    }
                }
            }
        }
    }
}

@media (max-width: 1245px) {
    .hero-container {
        min-height: 60vh;

        .hero-content {
            .top-container-hero {
                .top-left-container {
                    .birds {
                        img {
                            width: 20vw;
                        }
                    }
                    .hero-text {
                        .hero-title {
                            font-size: 260px;

                            @media (max-width: 1024px) {
                                font-size: 200px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 820px) {
    .hero-container {
        display: none;
    }
}

@keyframes fly {
    0% { transform: translateX(0); }
    100% { transform: translateX(100px); }
}
@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;