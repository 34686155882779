.card-component {
    perspective: 1000px;
    width: 100%;
    height: 100%;
    padding: 32px;
    box-sizing: border-box;
    cursor: pointer;
    padding-top: 56px;
    padding-bottom: 56px;

    .card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        border: 1px solid @primary-color;
        min-height: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
    }

    &.flipped .card-inner {
        transform: rotateY(180deg);
    }

    .card-front,
    .card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        border-radius: 20px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .card-front {
        background-color: #fff;
        color: black;
        border: 1px solid @primary-color;
        justify-content: space-around;
    }

    .card-back {
        background-color: @primary-color;
        color: white;
        transform: rotateY(180deg);
        border: 1px solid @primary-color;
    }

    .image-container {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
        border-radius: 50px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

        img {
            width: 48px;
        }
    }

    .text-container {
        text-align: center;

        .card-title {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .card-description {
        font-size: 16px;
        text-align: justify;
    }
}

@media (max-width: 820px) {
    .card-component {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35vw;
    }
}

@media (max-width: 559px) {
    .card-component {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70vw;
    }
}

@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;