.checkout-container {
    display: flex;
    flex-direction: column; /* Stacks header, content, and footer */
    min-height: 100vh; /* Full height */
    justify-content: space-between; /* Space between header and footer */
    align-items: center; /* Center content */
    box-sizing: border-box;
    position: relative;
    overflow: hidden; /* Ensure elements don't overflow */

    .title-logo-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90vw; /* Match the width of the product container */
        padding-top: 100px;
        padding-bottom: 20px;
        box-sizing: border-box;
        
        .logo {
            width: 100px; /* Adjust the size of the logo as needed */
        }
    }
}

.shop-title {
    font-size: 52px;
    font-weight: 500;
    flex-grow: 1; /* Push the title to the center */
    text-align: center; /* Center align the title */
    filter: drop-shadow(6px 6px #0000002f);

}

.hasImage:hover section {
    background-color: rgba(5, 5, 5, 0.4);
  }
  .hasImage:hover button:hover {
    background: rgba(5, 5, 5, 0.45);
  }
  
  #overlay p,
  i {
    opacity: 0;
  }
  
  #overlay.draggedover {
    background-color: rgba(255, 255, 255, 0.7);
  }
  #overlay.draggedover p,
  #overlay.draggedover i {
    opacity: 1;
  }
  
  .group:hover .group-hover\:text-blue-800 {
    color: #2b6cb0;
  }

  .confirm-order-btn {
    background-color: @primary-color;
}

.confirm-order-btn:hover {
    background-color: #FEDEA0 !important;
    color: black;
} 

.cancel-order-btn {
    background-color: white;
    color: @primary-color;
    border-color: @primary-color;
    margin-right: 10px;
}

.cancel-order-btn:hover {
    background-color: #FEDEA0 !important;
    border-color: #FEDEA0;
    color: black;
}
@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;