.faq-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.company-logo {
    position: absolute;
    top: 100px; /* Adjust as necessary */
    left: 40px; /* Adjust for desired spacing */
    // img {
    //      width: 150px; /* Adjust size */
    // }
}

.faq-content-container {
    width: 100%;
    margin: auto;
    padding-top: 36px;
    padding-bottom: 96px;
    min-height: 70vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .faq-header {
        margin-top: 120px;
        font-size: 72px;
        font-weight: 400;
        filter: drop-shadow(6px 6px #0000002f);
    }

    .faq-sub-header {
        margin-top: 40px;
        font-size: 28px;
        font-weight: 700;
        color: @primary-color;
        line-height: 24px;
    }

    .faq-main-header {
        width: 40vw;
        font-size: 20px;
        font-weight: 500;
        margin-top: 12px;
        text-align: center;
    }

    .faq-qna-container {
        width: 100%;
        margin-top: 16px;
    }

    .faq-qna-item-container {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .faq-qna-item-collapse {
            background-color: #FEDEA0;
            // box-shadow: 1px 1px 12px 0px #B38922;
            border: 1px solid #e7e7e700;
            width: 50%;
            border-radius: 20px;

            .ant-collapse-arrow {
                font-size: 24px;
            }

            .ant-collapse-content {
                border-top: 1px solid @primary-color;
            }

            .ant-collapse-content-box {
                background-color: #ffffed;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                // border: 1px solid #FEDEA0;
            }
        }

        .faq-question-number {
            color: @primary-color;
            font-weight: bold;
        }
    }
}

@media (max-width: 1024px) {
    .faq-content-container {
        .faq-qna-item-container {
            .faq-qna-item-collapse {
                width: 70%;
            }
        }
    }
}

@media (max-width: 820px) {
    .company-logo {
        position:relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 32px;
        left: 0px; 
        top: 0px
    }   

    .faq-content-container {  
        .faq-header {
            margin-top: 0px;
        }
        .faq-sub-header {
            margin-top: 12px;
            font-size: 32px;
        }
    
        .faq-main-header {
            width: 80vw;
            font-size: 28px;
            margin-bottom: 24px;
        }
        
        .faq-qna-item-container {
            .faq-qna-item-collapse {
                width: 70%;
            }
        }
    }
}

@media (max-width: 576px) {
    .faq-content-container {  
        .faq-qna-container {
            margin-top: 0px;
        }

        .faq-sub-header {
            margin-top: 12px;
            font-size: 20px;
        }
    
        .faq-main-header {
            font-size: 16px;
        }
        
        .faq-qna-item-container {
            .faq-qna-item-collapse {
                width: 70%;
            }
        }
    }
}

@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;