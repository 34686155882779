.benefits-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: auto;
    padding: 80px 0;
}

.card-carousel-benefits {
    display: none; /* Hidden on desktop */
    margin: 0 auto; /* Center the carousel */
    text-align: center;
}

.react-multi-carousel-item {
    display: flex;
    justify-content: center;  /* Center the carousel items */
}

.background-rectangle {
    position: absolute;
    top: 325px;
    width: 100vw;
    height: 500px;
    background: linear-gradient(90deg, #d4a037, #f2d49b);
    z-index: -1;
}

.benefits-sub-heading {
    margin-bottom: 0px;
    font-size: 40px;
    color: @primary-color;
    text-align: center;
}

.benefits-heading {
    font-size: 40px;
    text-align: center;
}

.benefits-description {
    font-size: 20px;
    text-align: justify;
    margin: 12px;
    color: rgb(105, 105, 105);
}

.vertical-spacer {
    height: 40px;
    width: 100%;
}

.benefits-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    z-index: 1;
}

.benefits-column {
    flex: 1 1 calc(33.33% - 32px);
    max-width: calc(33.33% - 32px);
    min-height: 320px;
    margin: 16px;
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.benefits-column.visible {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 820px) {
    .vertical-spacer {
        height: 0px;
        width: 100%;
    }

    .benefits-heading {
        font-size: 36px;
    }

    .benefits-sub-heading {
        font-size: 36px;
    }

    /* Hide benefit cards on mobile */
    .benefits-card-container {
        display: none; /* Hide the static cards */
    }

    /* Show carousel on mobile */
    .card-carousel-benefits {
        display: block;
        width: 100%;
    }

    .background-rectangle {
        top: 225px;
        height: 240px;
    }
}

@media (max-width: 576px) {
    .benefits-heading {
        font-size: 32px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .benefits-sub-heading {
        font-size: 32px;
    }

    .background-rectangle {
        height: 220px;
    }
}

@media (max-width: 365px) {
    .benefits-heading {
        font-size: 32px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .benefits-sub-heading {
        font-size: 32px;
    }

    .background-rectangle {
        height: 220px;
    }
}

@media (max-width: 354px) {
    .benefits-heading {
        font-size: 32px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .benefits-sub-heading {
        font-size: 32px;
    }

    .background-rectangle {
        top: 60%; /* Use percentage values for better flexibility */
        transform: translateY(-50%);
        height: 220px;
    }
}

@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;