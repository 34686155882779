.language-switcher-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FEDEA0;
    border-radius: 50px;
    padding: 0px 12px;
    margin-left: 32px;

    p span:not(:first-child) {
        border-left: 1px solid #FEDEA0;
    }

    p span{
        cursor: pointer;
        padding: 0px 8px;
        color: #FEDEA0;
    }
}

&.blacked .language-switcher-container {
    p span:not(:first-child) {
        border-left: 1px solid #000000;
    }
}

.language-switcher-divider {
    margin: 0;
    background-color: #FEDEA0;
}

.language-dropdown .ant-select-selector {
    width: 80px !important;
    color: #808080;
    border: 1px solid#808080 !important;
}

.language-dropdown .ant-select-arrow {
    color: #808080;
}

.text {
    color: #ffffff;
}

&.blacked .text {
    color: #000;
}

@media (max-width: 820px) {
    .language-switcher-container {
        margin-left: 0px;
    }
}
@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;