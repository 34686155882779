.hero-mobile-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    overflow: hidden;
    display: none;

    .hero-mobile-header-background {
        width: 100vw;
        background-color: #34312F;

        img {
            height: 9vh;
        }
    }
    
    .hero-mobile-header {
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: center;

        .hero-mobile-header-line {
            display: flex;
            flex-direction: column;
            margin-left: 12px;

            h1 {
                font-size: 20px;
                margin: 0;
                color: @primary-color;
                text-align: center;
            }
        }
    }

    .hero-mobile-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 80vh;
        text-align: center;

        .background-image {
            position: relative;
            width: 100%;
            height: 100%;
            // background: url('../../assets/images/Group35.png') right 7% center/cover no-repeat;
            background: url('../../assets/images/Group35.webp') center/cover no-repeat;
            background-position-y: 20px;
            background-size: 600px 600px;
            display: flex;
            justify-content: center;
            align-items: center; 

            .hero-mobile-title {
                position: absolute;
                top: 0%;
                left: 10%;
                color: #B38922;
                text-align: left;
                margin-right: 20px;

                h1 {
                    font-size: 64px;
                    margin: 0;
                    background: -webkit-linear-gradient(0deg, #B38922 0%, #FEDEA0 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 900;
                    filter: drop-shadow(2px 2px #00000088);

                    span {
                        font-size: 24px;
                        background: -webkit-linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                .hero-subtitle {
                    margin-top: 8px;
                    font-size: 16px;
                    color: #B38922;
                    filter: drop-shadow(1px 1px #000000cb);
                    // background: -webkit-linear-gradient(0deg, rgba(179,137,34,1) 0%, rgba(254,222,160,1) 100%);
                    // background-clip: text;
                    // -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;

                    &.hero-subtitle-zh {
                        font-size: 20px; /* Adjust font size for Chinese text as needed */
                        line-height: 1.5;  /* Optionally adjust line height for better readability */
                    }
                }
            }
        }
    }
}


@media (max-width: 820px) {
    .hero-mobile-container {
        display: flex;
        .hero-mobile-content {
            // height: 100vh;
            .background-image {
                .hero-mobile-title {
                    top: 6%;
                    left: 450px;
    
                    h1 {
                        font-size: 100px;
                        margin: 0;
                        font-weight: 500;
                        text-align: right;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        text-align: right;
                        margin-top: 8px;
                        font-size: 20px;
                        color: #B38922;
                    }
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .hero-mobile-container {
        .hero-mobile-content {
            .background-image {
                .hero-mobile-title {
                    top: 8%;
                    left: 50%;
    
                    h1 {
                        font-size: 110px;
                        margin: 0;
                        font-weight: 500;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        margin-top: 8px;
                        font-size: 24px;
                        color: #B38922;
                    }
                }

                .hero-mobile-title-zh {
                    top: 4%;
                    left: 50%;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .hero-mobile-container {
        .hero-mobile-content {
            .background-image {
                .hero-mobile-title {
                    top: 10%;
                    left: 45%;
    
                    h1 {
                        font-size: 92px;
                        margin: 0;
                        font-weight: 500;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        margin-top: 8px;
                        font-size: 22px;
                        color: #B38922;
                        // background: -webkit-linear-gradient(0deg, rgba(179,137,34,1) 0%, rgba(254,222,160,1) 100%);
                        // background-clip: text;
                        // -webkit-background-clip: text;
                        // -webkit-text-fill-color: transparent;
                    }
                }

                .hero-mobile-title-zh {
                    top: 5%;
                    left: 55%;
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .hero-mobile-container {
        .hero-mobile-content {
            .background-image {
                .hero-mobile-title {
                    top: 5%;
                    left: 40%;
    
                    h1 {
                        font-size: 88px;
                        margin: 0;
                        font-weight: 500;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        margin-top: 8px;
                        font-size: 22px;
                        color: #B38922;
                        // background: -webkit-linear-gradient(0deg, rgba(179,137,34,1) 0%, rgba(254,222,160,1) 100%);
                        // background-clip: text;
                        // -webkit-background-clip: text;
                        // -webkit-text-fill-color: transparent;
                    }
                }

                .hero-mobile-title-zh {
                    top: 5%;
                    left: 55%;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .hero-mobile-container {

        .hero-mobile-content {
            .background-image {
                .hero-mobile-title {
                    top: 5%;
                    left: 35%;
    
                    h1 {
                        font-size: 76px;
                        margin: 0;
                        font-weight: 500;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        margin-top: 8px;
                        font-size: 19px;
                        color: #B38922;
                        // background: -webkit-linear-gradient(0deg, rgba(179,137,34,1) 0%, rgba(254,222,160,1) 100%);
                        // background-clip: text;
                        // -webkit-background-clip: text;
                        // -webkit-text-fill-color: transparent;
                    }
                }   

                .hero-mobile-title-zh {
                    top: 5%;
                    left: 55%;
                }
            }
        }
    }
}


@media (max-width: 425px) {
    .hero-mobile-container {
        .hero-mobile-content {
            .background-image {
                .hero-mobile-title {
                    top: 4%;
                    left: 30%;
    
                    h1 {
                        font-size: 62px;
                        margin: 0;
                        font-weight: 700;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        text-align: right;
                        margin-top: 8px;
                        font-size: 20px;
                        color: #B38922;
                    }
                }

                .hero-mobile-title-zh {
                    top: 5%;
                    left: 60%;
                }
            }
        }
    }
}

@media (max-width: 414px) {
    .hero-mobile-container {
        .hero-mobile-content {
            .background-image {
                .hero-mobile-title {
                    top: 4%;
                    left: 30%;
    
                    h1 {
                        font-size: 62px;
                        margin: 0;
                        font-weight: 700;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        text-align: right;
                        margin-top: 8px;
                        font-size: 18px;
                        color: #B38922;
                    }
                }

                .hero-mobile-title-zh {
                    top: 5%;
                    left: 60%;
                }
            }
        }
    }
}

@media (max-width: 390px) {
    .hero-mobile-container {
        .hero-mobile-content {
            .background-image {
                .hero-mobile-title {
                    top: 4%;
                    left: 35%;
    
                    h1 {
                        font-size: 64px;
                        margin: 0;
                        font-weight: 500;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        margin-top: 8px;
                        font-size: 16px;
                        color: #B38922;
                    }
                }

                .hero-mobile-title-zh {
                    top: 4%;
                    left: 60%;
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .hero-mobile-container {
        .hero-mobile-content {
            .background-image {
                .hero-mobile-title {
                    top: 4%;
                    left: 35%;
    
                    h1 {
                        font-size: 64px;
                        margin: 0;
                        font-weight: 500;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        margin-top: 8px;
                        font-size: 15px;
                        color: #B38922;
                    }
                }

                .hero-mobile-title-zh {
                    top: 4%;
                    left: 55%;
                }
            }
        }
    }
}

@media (max-width: 320px) {
    .hero-mobile-container {
        .hero-mobile-content {
            .background-image {
                .hero-mobile-title {
                    top: 4%;
                    left: 33%;
    
                    h1 {
                        font-size: 64px;
                        margin: 0;
                        font-weight: 500;
    
                        span {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
    
                    .hero-subtitle {
                        margin-top: 8px;
                        font-size: 14px;
                        color: #B38922;
                    }
                }
                .hero-mobile-title-zh {
                    top: 4%;
                    left: 50%;
                }
            }
        }
    }
}

@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;