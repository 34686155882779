.floating-nav {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    background: #34312F; /* Slightly transparent */
    backdrop-filter: blur(10px); /* Adds a blur effect behind the nav */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    z-index: 1000; /* Keeps the nav on top of other elements */
    transition: background-color 0.3s ease, padding 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .hero-mobile-header-background {
        // width: 100vw;
        background-color: #34312F;
        margin-left: 40px;

        img {
            height: 9vh;
        }
    }

    .hero-mobile-header {
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: center;
        text-align: left;


        .hero-mobile-header-line {
            display: flex;
            flex-direction: column;
            margin-left: 12px;

            h1 {
                font-size: 20px;
                margin: 0;
                color: @primary-color;
                text-align: left;
            }
        }
    }

    .nav-container {
        margin: 0;
        padding: 12px 20px;
        display: flex;
        justify-content: right;
        align-items: center;

    }

    .logo {
        img {
            height: 48px; /* Adjust the logo size */
        }
    }

    .nav-links {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        li {
            margin: 0 15px;

            a {
                text-decoration: none;
                color: #FEDEA0;
                font-weight: 500;
                transition: color 0.3s ease;

                &:hover {
                    color: #fedfa07b; /* Adjust hover color */
                }
            }
        }
    }

    .cta-button {
        button {
            padding: 10px 20px;
            background-color: #154C79;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #0d3c63; /* Adjust hover background color */
            }
        }
    }
}

@media (max-width: 820px) {
    .floating-nav {
        display: none;
    }
}

@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;