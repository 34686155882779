.landing-content-container {
    .title-line {
        height: 2px; /* Thickness of the line */
        background-color: #000000; /* Color of the line */
        width: 100%; /* Adjust the width as needed */
        margin: 20px 0; /* Adds some spacing between the title and the line */
    }
    
    .landing-content-1,
    .landing-content-2,
    .landing-content-3,
    .landing-content-4 {
        display: flex;
        width: 100%;
        margin: auto;
        min-height: 70vh;
        align-items: center;
        justify-content: space-around;
        margin-top: 0px;
        margin-bottom: 0px;
        position: relative;
    }

    /* Background div behind everything */
    .background-div-1,
    .background-div-3 {
        position: absolute;
        width: 100vw;
        height: 70vh;
        background-size: cover;
        background-position: center;
        z-index: -1;
        overflow: hidden; /* Ensures that the pseudo-element is contained */
    }

    /* Overlay the #FEDEA0 color with opacity */
    .background-div-1::before,
    .background-div-3::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #FEDEA0;
        opacity: 0.8; /* Adjust opacity as needed */
        z-index: -1; /* Keeps the overlay behind content */
    }

    .background-div-1 {
        background-image: url('../../assets/images/bg-img-1.webp');
        transform: translateY(80px);
    }

    .background-div-3 {
        background-image: url('../../assets/images/bg-img-2.webp');
        transform: translateY(100px);
    }

    .landing-content-1,
    .landing-content-3 {
        flex-direction: row-reverse;
    }

    .landing-content-image-container-1,
    .landing-content-image-container-2,
    .landing-content-image-container-3,
    .landing-content-image-container-4 {
        height: 60vh;
    
        .landing-content-image-1,
        .landing-content-image-2,
        .landing-content-image-3,
        .landing-content-image-4 {
            width: 100%;
            height: 100%;
        }
    }

    .landing-content-text-1,
    .landing-content-text-2,
    .landing-content-text-3,
    .landing-content-text-4 {
        display: flex;
        flex-direction: column;
        width: 40%;

        .landing-content-title-2,
        .landing-content-title-4  {
            margin-bottom: 0px;
        }

        .landing-content-title-1,
        .landing-content-title-2,
        .landing-content-title-4 {
            font-size: 52px;
            filter: drop-shadow(4px 4px #0000002f);
        }

        .landing-content-subtitle-3-3 {
            margin-bottom: 0px;
        }

        .landing-content-title-2,
        .landing-content-subtitle-3-1,
        .landing-content-subtitle-3-2,
        .landing-content-subtitle-3-3 {
            filter: drop-shadow(4px 4px #0000002f);
            span {
                color: #B38922;
            }
        }

        .landing-content-subtitle-1 {
            font-size: 32px;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .landing-content-subtitle-3-1,
        .landing-content-subtitle-3-2,
        .landing-content-subtitle-3-3 {
            font-size: 40px;
            margin-top: 0px;
        }

        .landing-content-subtitle-3-1,
        .landing-content-subtitle-3-2 {
            margin-bottom: 12px;
        }

        .landing-content-description-1,
        .landing-content-description-2,
        .landing-content-description-3,
        .landing-content-description-4 {    
            font-size: 20px;
            margin-top: 0px;
        }
    }
}

@media (max-width: 820px) {
    .landing-content-container {

        .background-div-1 {
            transform: translateY(150px);
        }
    
        .background-div-3 {
            transform: translateY(60px);
        }

        .landing-content-1,
        .landing-content-2,
        .landing-content-3,
        .landing-content-4 {
            flex-direction: column;
            padding-top: 48px;
        }

        .landing-content-image-container-1,
        .landing-content-image-container-2,
        .landing-content-image-container-3,
        .landing-content-image-container-4 {
            height: 50vh;
        
            .landing-content-image-1,
            .landing-content-image-2,
            .landing-content-image-3,
            .landing-content-image-4 {
                width: 100%;
                height: 100%;
            }
        }

        .landing-content-text-3 {
            margin-top: 16px;
        }

        .landing-content-text-1,
        .landing-content-text-2,
        .landing-content-text-3,
        .landing-content-text-4 {
            display: flex;
            flex-direction: column;
            width: 80%;
            text-align: center;
    
            .landing-content-title-1,
            .landing-content-title-2,
            .landing-content-title-4 {
                font-size: 38px;
            }
    
            .landing-content-title-2,
            .landing-content-subtitle-3-1,
            .landing-content-subtitle-3-2,
            .landing-content-subtitle-3-3 {
                span {
                    color: #B38922;
                }
            }
    
            .landing-content-subtitle-1 {
                font-size: 26px;
                margin-top: 0px;
            }
    
            .landing-content-subtitle-3-1,
            .landing-content-subtitle-3-2,
            .landing-content-subtitle-3-3 {
                font-size: 32px;
                margin-top: 0px;
            }
    
            .landing-content-subtitle-3-1,
            .landing-content-subtitle-3-2 {
                margin-bottom: 12px;
            }
    
            .landing-content-description-1,
            .landing-content-description-2,
            .landing-content-description-3,
            .landing-content-description-4 {    
                font-size: 16px;
                margin-top: 0px;
            }
        }
    }
}

@media (max-width: 576px) {
    .landing-content-container {

        .background-div-1 {
            transform: translateY(100px);
        }
    
        .background-div-3 {
            transform: translateY(40px);
        }

        .landing-content-1,
        .landing-content-2,
        .landing-content-3,
        .landing-content-4 {
            flex-direction: column;
            padding-top: 48px;
        }

        .landing-content-image-container-1,
        .landing-content-image-container-2,
        .landing-content-image-container-3,
        .landing-content-image-container-4 {
            height: 45vh;
            width: 60vw;
        
            .landing-content-image-1,
            .landing-content-image-2,
            .landing-content-image-3,
            .landing-content-image-4 {
                width: 100%;
                height: 100%;
            }
        }

        .landing-content-text-3 {
            margin-top: 16px;
        }

        .landing-content-text-1,
        .landing-content-text-2,
        .landing-content-text-3,
        .landing-content-text-4 {
            display: flex;
            flex-direction: column;
            width: 80%;
            text-align: center;
    
            .landing-content-title-1,
            .landing-content-title-2,
            .landing-content-title-4 {
                font-size: 26px;
            }
    
            .landing-content-title-2,
            .landing-content-subtitle-3-1,
            .landing-content-subtitle-3-2,
            .landing-content-subtitle-3-3 {
                span {
                    color: #B38922;
                }
            }
    
            .landing-content-subtitle-1 {
                font-size: 20px;
                margin-top: 0px;
            }
    
            .landing-content-subtitle-3-1,
            .landing-content-subtitle-3-2,
            .landing-content-subtitle-3-3 {
                font-size: 26px;
                margin-top: 0px;
            }
    
            .landing-content-subtitle-3-1,
            .landing-content-subtitle-3-2 {
                margin-bottom: 12px;
            }
    
            .landing-content-description-1,
            .landing-content-description-2,
            .landing-content-description-3,
            .landing-content-description-4 {    
                font-size: 16px;
                margin-top: 0px;
            }
        }
    }
}

@media (max-width: 394px) { 
    .landing-content-container {
        .background-div-1 {
            transform: translateY(160px);
        }

        .background-div-3 {
            transform: translateY(40px);
        }
    }
}
@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;