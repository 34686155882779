.shop-container {
    display: flex;
    flex-direction: column; /* Stacks header, content, and footer */
    min-height: 100vh; /* Full height */
    justify-content: space-between; /* Space between header and footer */
    align-items: center; /* Center content */
    box-sizing: border-box;
    position: relative;
    overflow: hidden; /* Ensure elements don't overflow */

    .title-logo-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90vw; /* Match the width of the product container */
        padding-top: 100px;
        padding-bottom: 20px;
        box-sizing: border-box;
        
        .logo {
            width: 100px; /* Adjust the size of the logo as needed */
        }
    }
}

.shop-leaf-left, .shop-leaf-right {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
}

.shop-leaf-left {
    top: 420px;
    left: 0;
    width: 420px; /* Adjust the width */
    background-image: url('../../assets/images/shop-leaf-left.svg');
}

.shop-leaf-right {
    top: 180px;
    right: 0;
    width: 420px; /* Adjust the width */
    background-image: url('../../assets/images/shop-leaf-right.svg');
}

.shop-title {
    font-size: 52px;
    font-weight: 500;
    flex-grow: 1; /* Push the title to the center */
    text-align: center; /* Center align the title */
    filter: drop-shadow(6px 6px #0000002f);

}

.product-container {
    width: 70vw;
    max-width: 1200px;
    padding: 20px;
    padding-bottom: 52px; /* Remove top padding since we have padding in title container */
    box-sizing: border-box;
    
    .ant-input {
        font-size: 16px;
    }
}

.ant-card-bordered {
    border: 1px solid #a9a9a9;
}

.ant-card-cover img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.product-modal-footer {
    display: flex;
    justify-content: flex-end;

    .buy-now-btn {
        background-color: @primary-color;
    }

    .buy-now-btn:hover {
        background-color: #FEDEA0 !important;
        color: black;
    }

    .add-to-cart-btn{
        color: @primary-color;
        background-color: white;
        border-color: @primary-color;
    }

    .add-to-cart-btn:hover {
        background-color: #FEDEA0 !important;
        color: black;
        border-color: #FEDEA0;
    }
}

.product-modal-content {
    .ant-carousel .slick-prev, .ant-carousel .slick-next {
        width: 40px;
        height: 40px;
        z-index: 1;
    }

    .ant-carousel .slick-prev::before, .ant-carousel .slick-next::before {
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        z-index: -1;
    }

    .ant-carousel .slick-prev::before {
        transform: translate(-85%, -80%);
    }

    .ant-carousel .slick-next::before {
        transform: translate(-75%, -80%);
    }

    .ant-carousel .slick-prev {
        left: 20px;
    }

    .ant-carousel .slick-next {
        right: -5px;
    }

    .ant-carousel .slick-prev, .ant-carousel .slick-next {
        font-size: 16px;
        color: #fff;
    }

    .ant-carousel .slick-prev:hover::before, .ant-carousel .slick-next:hover::before {
        background-color: rgba(0, 0, 0, 0.7);
    }
}
    
@media (max-width: 820px) {
    .shop-container {
        .product-container {
            width: 85vw;
            max-width: 1200px;
            padding: 20px;
            padding-bottom: 52px; /* Remove top padding since we have padding in title container */
            box-sizing: border-box;
            
            .ant-input {
                font-size: 16px;
            }
        }

        .title-logo-container {
            flex-direction: column;
            padding-top: 32px;
        }
    }
}

@media (max-width: 520px) {
    .shop-leaf-left {
        display: none;
    }
    
    .shop-leaf-right {
        display: none;
    }
}
@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;