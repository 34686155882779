.cta-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(45deg, rgba(179,137,34,1), rgba(254,222,160,1));
    border-radius: 20px;
    padding: 20px 40px;
    color: white;
    // width: 100%;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin-top: 96px;
}
  
.cta-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
}

.cta-subtitle {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: black;

}

.cta-title {
  margin: 5px 0;
  font-size: 28px;
  font-weight: bold;

  @media (max-width: 375px) {
    font-size: 24px;
  }

  @media (max-width: 320px) {
    font-size: 20px;
  }
}

.cta-arrow {
  display: flex;
  align-items: center;

  hr {
    width: 100px;
    border: 0;
    border-top: 2px solid rgb(0, 0, 0);
    margin: 0 10px;
  }

  span {
    font-size: 24px;
    font-weight: bold;
    animation: moveLeftRight 1.5s ease-in-out infinite; /* Arrow animation */
  }
}

.action-button {
  position: relative;
  font-size: 16px;
  // text-transform: uppercase;
  text-decoration: none;
  padding: 12px 28px;
  display: inline-block;
  border-radius: 6em;
  transition: all .2s;
  border: none;
  font-family: inherit;
  font-weight: 600;
  color: @primary-color;
  background-color: white;
  cursor: pointer;
}
 
.action-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
 
.action-button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
 
.action-button::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s;
}
 
.action-button::after {
  background-color: #fff;
}
 
.action-button:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

@media (max-width: 820px) {
  .cta-container {
    flex-direction: column;
    text-align: center;
    width: 60vw;

    .cta-subtitle {
      margin-bottom: 12px;
    }

    .cta-title {
      margin-bottom: 20px;
    }
  }

  .cta-arrow {
    display: none;
    justify-content: center;

    hr {
      width: 50px;
    }

    span {
      font-size: 20px;
    }
  }
}

@keyframes moveLeftRight {
  0% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(10px);
  }
  100% {
      transform: translateX(0);
  }
}

@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;