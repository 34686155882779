

.cart-button-container {
    position: fixed;
    width: 52px;
    height: 52px;
    bottom: 90px;
    right: 20px;
    background-color: #B38922;
    color: #FFF !important; 
    border-radius: 50%;
    text-align: center;
    font-size: 32px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: bottom 0.3s ease;
    cursor: pointer;
    border: 0px;
}

.cart-button-container .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.cart-button-container .cart-logo {
    width: 32px;
    height: 32px; 
color: white;
fill: white !important; 
}

@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;